<template>
  <div id="paymentchannel-setting">
    <v-data-table
      :headers="header"
      :items="clearCacheData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :height="530"
      :search="inputSearch"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()"
      @update:expanded="updateExpanded()"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize"> Clear Cache </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red darken-1"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on">
                <v-icon> mdi-cached </v-icon>
                Clear Cache
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()"
              >
                <v-card-title> Clear Cache </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col>
                        ล้าง Cache ของสินค้าที่ติดอยู่หน้า Website
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color=""
                    text
                    @click="dialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="red darken-1"
                    type="submit"
                    text
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template #[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTimeFormat() }}
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CacheProvider from '@/resources/CacheProvider'

const CacheService = new CacheProvider()

export default {
  data () {
    return {
      inputSearch: '',
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Date',
          value: 'createdAt',
          align: 'center'
        },
        {
          text: 'Message',
          value: 'message',
          align: 'center'
        },
        {
          text: 'Pattern',
          value: 'pattern',
          align: 'center'
        }
      ],
      clearCacheData: [],
      getting: false,
      modalLoading: false
    }
  },
  computed: {
    search () {
      return this.$route.query.search ? this.$route.query.search : ''
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions({}),
    setPageCount (event) {
      this.pageCount = event
    },
    async getData () {
      try {
        this.loading = true
        const { data: { results } } = await CacheService.getClearCacheLogs(
          {
            ...this.options,
            search: this.search
          }
        )
        this.clearCacheData = results
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async save () {
      this.modalLoading = true
      try {
        await CacheService.clearCaches('*')
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.dialog = false
        this.modalLoading = false
        this.getData()
      }
    }
  }
}
</script>
