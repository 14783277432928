import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

export default class CacheProvider extends HttpRequest {
  getClearCacheLogs (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/clear-cache-logs', query)
  }

  clearCaches (pattern) {
    this.setHeader(getAuthToken())
    return this.delete(`/caches/pattern?pattern=${pattern}`)
  }
}
